.sign-up-modal {
	p {
		a {
			color: #333333;
			&:hover {
				text-decoration: underline;
			}
		}
	}
	text-align: center;
  .modal-dialog {
    background: #fff;
    width: 95%;
    max-width: 1100px;
    margin-left: auto;
    margin-right: auto;
  }
  .modal-content {
    border-radius: 0;
    border: none;
		padding: 65px 0;
		.close-button {
			position: absolute;
			top: 10px;
			right: 15px;
		}
  }
  .form-control {
    height: 48px;
		width: calc(~"50% - 30px");
		float: left;
		padding: 0 15px;
		margin: 0 15px 10px;
		border-radius: 0;
    input, select {
      border: none;
      height: 100%;
      width: 100%;
      float: left;
			background-color: #fff;
    }
		&.select {
			width: calc(~"100% - 30px");
		}
		&.aus-states{
			display: none;
		}
		&.uk-cities{
			display: none;
		}
  }
	.btn {
		border: none;
	}
}
.sign-up-modal__headline h3 {
	color: #273167;
	font-weight: 400;
	font-size: 32px;
	line-height: 38px;
	margin-bottom: 30px;
	margin-left: auto;
	margin-right: auto;
	max-width: 420px;
	span {
		font-weight: 500;
		&.light {
			font-weight: 300;
		}
	}
	.tm {
		font-size: 11px;
    line-height: 11px;
    position: relative;
    top: -13px;
    padding-right: 2px;
		displaY: inline;
	}
}
.sign-up-modal__content {
	max-width: 555px;
	margin-left: auto;
	margin-right: auto;
	.btn {
		margin-bottom: 30px;
	}
}
.modal-backdrop {
	bottom: 0;
	background: rgba(23, 56, 97, 1);
}

.errors-box {
	background-color: rgba(19, 41, 79, 0.4);
    width: 100%;
    margin: 0 auto;
    clear: both;
    margin-bottom: 20px;
    padding: 10px;
    max-width: 527px;
    display: none;

	span {
		color: #fff;

		i {
			margin-right: 10px;
		}
	}
}


@media(max-width: 767px) {
	.sign-up-modal {
		.sign-up-modal__headline h3 {
			font-size: 20px;
			line-height: 24px;
			margin-bottom: 15px;
			.tm {
				top: -7px;
			}
		}
		.sign-up-modal__content {
			.btn {
				margin-bottom: 15px;
			}
		}
		.form-control {
			width: calc(~"100% - 30px");
			&.select {
				margin-bottom: 15px;
			}
		}
		.modal-content {
			padding: 20px 0;
		}
	}
}