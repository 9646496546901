
.md-button{
	margin:0;
}

.md-button.md-primary{
	background-color:@color-blue;
}

.md-button.md-warn{
	background-color:@color-red;
}

md-input-container label:not(.md-no-float):not(.md-container-ignore), md-input-container .md-placeholder{
	color: #626262;
}

md-slider .md-track.md-track-fill, md-slider .md-thumb:after{
	transition:background-color 0.25s ease-in-out;
}

md-input-container.md-default-theme .md-input, md-input-container .md-input{
	border-color: @color-blue;
}
