.content-info {
  padding: 2.5em 0;
  background: #263268;
  color: #fff;
  p {
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    line-height: 19px;
  }
  h3 {
    font-size: 24px;
    margin-top: 0;
    color: #fff;
  }
  ul {
    margin: 0;
    padding: 0;
    li {
      margin: 0 5% 0 0;
      padding: 0;
      width: 45%;
      float: left;
      list-style-type: none;
      border-bottom: 1px solid #fff;
      a {
        font-size: 14px;
        display: block;
        padding: 5px 0 3px;
      }
    }
  }
  a {
    color: #fff;
  }
}
.copyinf {
  color: #fff;
  background: #1E295D;
  padding: 10px 0;
  font-size: 14px;
  line-height: 20px;
  .social {
    float: right;
  }
  a {
    color: #fff;
    font-size: 21px;
  }
}