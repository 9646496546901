/*Colors*/
@primary-font-color : #4A4A4A;
@second-font-color : #D01328;
@tertiary-font-color : #263267;
@fourth-font-color : #fff;
@fifth-font-color : #1F3C7B;

@paragraph-font-color : #262626;

@primary-color : #D01328;
@secondary-color : #263267;
@tertiary-color : #fff;
@dark-bg-color: #202762;
@highlight-color: #e75a0a;

@image-dir: "/app/themes/dent/assets/images/";

@border-primary : #EA5C18;

//Fonts

@font-size : 16;

@font-primary-light: 'HelveticaNeueETW01-45Lt', arial, sans-serif;
@font-primary-medium: 'HelveticaNeueETW01-65Md', arial, sans-serif;
@font-primary-regular: 'HelveticaNeueETW01-55Rg',arial, sans-serif;
@font-primary-bold: 'HelveticaNeueETW01-75Bd', arial, sans-serif;
@font-primary-roman : 'HelveticaNeueW01-55Roma', arial, sans-serif;

@font-secondary: arial, sans-serif;
@font-tertiary: arial, sans-serif;

/*Media Query functions */
@xs-screen: 480px;
@sm-screen: 767px;
@md-screen: 991px;
@lg-screen: 1024px;

body .Landing section.btm-slider {
  padding-top: 0;
  padding-bottom: 0;
}
.full-height {
  height: 100%;
}
.slider {
  padding-top: 140px;
  &.animate {
    padding-bottom: 0 !important;
  }
}
.slider-wrapper {
  overflow: hidden;
  position: relative;
  padding: 0 10px;
  .item {
    padding: 0 20px;
    opacity: 0;
    width: 100%;
    position: absolute;
    left: 100%;
    top: 0;
    .vertical-center {
      position: absolute;
      left: 0;
      padding: 30px;
      top: 40%;
    }
    h5 {
      opacity: 0;
      color: #fff;
    }
    &.active {
      opacity: 1;
      left: 0;
      top: 0;
      transition: 0.4s left;
      h5 {
        opacity: 1;
        transition: opacity 0.7s;
        transition-delay: 0.4s;
      }
    }
  }
  .item .vertical-center {
    h2 {
      font-size: 48px;
      line-height: 52px;
      margin-bottom: 20px;
      color: @fourth-font-color;
    }
    h3 {
      font-size: 33px;
      line-height: 39px;
      color: @fourth-font-color;
      font-family: @font-primary-regular;
      letter-spacing: -1px;
      margin-bottom: 20px;
      strong {
        font-family: @font-primary-bold;
      }
    }
  }
}
@media (min-width: 992px) {
  .slider-wrapper {
    .item {
      left: 0;
      top: 100%;
      &.active {
        left: 0;
        top: 0;
        transition: 0.4s top;
      }
    }
  }
}
.slide-pointer {
  position: absolute;
  left: 10px;
  color: @primary-color;
  top: 3px;
}
.slider-left-content {
  p {
    color: #fff;
  }
  a {
    color: #fff;
    &:hover {
      color: #fff;
    }
  }
}
@media (min-width: 992px) {
  .slides {
    width: 100%;
    overflow: hidden;
    height: 300px;
    position: relative;
    border-left: 1px solid @primary-color;
  }
  .slick-slider {
    h3 {
      font-size: (@font-size * 2) + 6;
      line-height: 56px;
    }
  }
  .slider-wrapper {
    .item {
      .vertical-center {
        top: 0%;
        transform: translateY(0%);
      }
      h3, h2 {
        font-size: 20px;
        line-height: 20px;
        margin-bottom: 20px;
        margin-top: 0;
      }
      h5 {
        font-size: 16px;
        line-height: 22px;
        color: #fff;
      }
    }
  }
  .slider-wrapper {
    padding: 0;
    margin-top: 0;
    overflow: visible;
    h3 {
      margin-bottom: 35px;
    }
    .item {
      .vertical-center {
        padding-top: 0;
      }
    }
  }
  .desktop-main-title {
    h3 {
      font-size: 47px;
      line-height: 52px;
      letter-spacing: -3px;
    }
  }
}
.hero-box {
  background-color: @primary-color;
  padding: 50px 30px;
  p {
    font-size: 16px;
    line-height: 24px;
    color: @fourth-font-color;
    margin-bottom: 20px;
    letter-spacing: -0.5px;
    strong, b {
     font-weight: 600;
    }
  }
  a {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: @fourth-font-color;
  }
}
@media (min-width: 992px) {
  .hero-box {
    position: relative;
    bottom: -24px;
    &:before {
      content: ' ';
      position: absolute;
      width: 0;
      height: 0;
      right: -20px;
      border-right: 20px solid transparent;
      border-top: 24px solid #8A0917;
      z-index: 0;
      bottom: 0;
    }
  }
}
@media (max-width: 991px ) {
  .desktop-bg {
    background-image: none !important;
  }
}
@media( min-width: 992px ) {
  .mob-bg {
    background-image: none !important;
  }
}