

body{
  :focus{
    outline:none !important;
  }
}

.Page-Container:not(.Landing):not(.app-footer):not(.app-header){
  padding-top:60px;
}

md-content{
  background-color:transparent;
}

.text-center{
  text-align:center;
}

.relative{
  position:relative;
}

.pull-left{
  float:left;
}

.pull-right{
  float:right;
}

md-list-item.active {
  background: rgba(63,81,181,0.1);
}

.btn{
  background: @color-orange;
  border-radius: 0;
  line-height:50px;
  padding:0 40px;
  font-family: @font-primary;
  font-size: 20px;
  color: white;
  font-weight:300;
  transition:@animate;
  display: inline-block;
  text-transform:none;
  color: white;
  text-decoration:none;

  &:not([disabled]):hover{
    background-color:#F44336;
    color:white;
  }

  &.btn-tertiary{
    background-color:#ccc;
    color:#6b6b6b;

    &:hover{
      background-color:rgb(189,189,189);
      color:#6b6b6b;
    }
  }

  &.btn-small{
    line-height:40px;
    padding:0 20px;
    font-size:16px;
  }
}

.main-wrapper{
  max-width:970px;
  padding:0 15px;
  margin:0 auto;
  width:calc(~"100% - 30px");
}

.intro{
  padding-bottom:30px;

  h1{
    margin-bottom:0px;
    margin-top:0px;

    span{
      font-family: @font-secondary;
      font-size: 30px;
      color: @color-light-red;
      line-height: 40px;
      font-weight:300;
      padding-right:30px;
      position:relative;
    }

    .tm{
      position:absolute;
      top:0px;
      right:0px;
      font-size:24px;
      font-weight:300;
      height:30px;
      line-height:30px;
    }
  }

  h2{
    /* Scoring your startin: */
    font-family: @font-primary;
    font-size: 20px;
    color: #626262;
    line-height: 24px;
    font-weight:100;
    margin-top:0px;
  }

  p{
    color: #626262;
  }
}

@media(min-width:600px){

  .intro{
    h1{

      span{
        font-size: 60px;
        line-height: 80px;
      }
    }

    h2{
      font-size: 36px;
      line-height: 48px;
    }
  }
}