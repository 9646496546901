footer{
  padding:40px 50px;

  img{
    width:200px;
  }

  .assets24{
    position:relative;
    width:170px;
    height:40px;
    float:left;
    margin-top:5px;

    span{
      color:black;
      font-family:@font-secondary;
      font-size:25px;
      font-weight:700;
      line-height:36px;
    }

    .tm{
      position:absolute;
      top:0px;
      right:40px;
      color:black;
      font-family:@font-secondary;
      font-size:12px;
    }
  }

  .footer-left{
    float:left;
  }

  .footer-right{
    float:right;
  }
}

@media(max-width:@laptop-max){
  footer{

    .assets24{
      padding-right:20px;
      margin-right:20px;
      width:120px;
      margin-top:6px;

      span{
        font-size:20px;
      }

      .tm{
        right:15px;
      }
    }
  }
}

@media(max-width:@tablet-max){
  footer{

    .footer-left{
      text-align: center;
      width: 260px;
      float: none !important;
      margin: 0 auto;
    }

    .footer-right{
      width:100%;
      text-align:center;
      margin-top:10px;
    }
  }
}

@media(max-width:@mobile-max){
  footer{
    padding:15px;
  }
}
