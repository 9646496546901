body {
  //font-family: @font-primary;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  .Landing {

    .background-cover {
      background-position: center;
      background-size: cover;
    }

    .vertical-center {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      z-index: 3;
    }

    .horizontal-center {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      z-index: 3;
    }

    .absolute-center {
      transform: translate(-50%, -50%);
      position: absolute;
      left: 50%;
      top: 50%;
      z-index: 3;
    }

    .cover {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0px;
      left: 0px;
    }

    .relative {
      position: relative;
    }

    .window-height {
      height: 100vh;
    }

    .btn {
      background: @color-orange;
      border-radius: 0px;
      line-height: 50px;
      padding: 0 20px;
      //font-family: @font-primary;
      font-size: 20px;
      color: white;
      font-weight: 400;
      transition: @animate;
      display: inline-block;
      cursor: pointer;
      &:hover, &:active, &:focus {
        background-color: @color-light-red;
        color: white;
        text-decoration: none;
      }
    }

    .btn-small {
      height: 34px;
      line-height: 32px;
      font-size: 14px;
    }

    .btn-clear {
      background-color: transparent;
      border: 1px solid @color-orange;
      color: white;
    }

    .flex {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }

    .hermit {
      opacity: 0;
    }

    .move-up {
      padding-top: 20px;
    }

    .move-up-complete {
      padding-bottom: 20px;
    }

    .animate {
      transition: @animate;
    }

    .delay-100 {
      transition-delay: 100ms;
    }
    .delay-200 {
      transition-delay: 200ms;
    }
    .delay-300 {
      transition-delay: 300ms;
    }
    .delay-400 {
      transition-delay: 400ms;
    }
    .delay-500 {
      transition-delay: 500ms;
    }
    .delay-600 {
      transition-delay: 600ms;
    }
    .delay-700 {
      transition-delay: 700ms;
    }
    .delay-800 {
      transition-delay: 800ms;
    }
    .delay-900 {
      transition-delay: 900ms;
    }
    .delay-1000 {
      transition-delay: 1000ms;
    }
    .delay-1100 {
      transition-delay: 1100ms;
    }
    .delay-1200 {
      transition-delay: 1200ms;
    }
    .delay-1300 {
      transition-delay: 1300ms;
    }
    .delay-1400 {
      transition-delay: 1400ms;
    }
    .delay-1500 {
      transition-delay: 1500ms;
    }
    .delay-1600 {
      transition-delay: 1600ms;
    }
    .delay-1700 {
      transition-delay: 1700ms;
    }
    .delay-1800 {
      transition-delay: 1800ms;
    }
    .delay-1900 {
      transition-delay: 1900ms;
    }
    .delay-2000 {
      transition-delay: 2000ms;
    }

    @media (max-width: @mobile-max) {

      .btn {
        height: 34px;
        line-height: 32px;
        font-size: 14px;
      }

      p {
        font-size: 13px;
        line-height: 22px;
        margin-bottom: 10px;
      }

      h3 {
        font-size: 19px;
        line-height: 24px;
        margin-bottom: 15px;
        margin-top: 0px;
      }

      h4 {
        font-size: 18px;
        line-height: 28px;
      }
    }
  }

  /*============== LANDING PAGE ===================*/

  .Landing {

    .logo {
      position: absolute;
      top: -60px;
      left: 60px;
      text-align: center;

      img {
        width: 190px;
        height: auto;
      }

      &:not(.hermit) {
        top: 15px;
      }
    }

    section {
      padding: 100px 0;
      background-color: white;

      &.apply-now-banner {
        padding: 25px 0;
      }

      &.intro {
        padding: 60px 0;
      }

      &.app-image {
        padding: 0px;
      }
    }

    .apply-span {
      /* APPLY NOW TO BE ONE : */
      font-family: @font-secondary;
      font-size: 12px;
      color: #FFFFFF;
      letter-spacing: 0.18px;
      line-height: 14px;
      text-transform: uppercase;
      display: inline-block;
    }

    .section-tag {
      color: white;
    }

    .banner {
      height: 100vh;
      position: relative;
      width: 100%;
      overflow: hidden;

      .background-cover {
        background-image: url('../../img/landing-banner.jpg');
        transform: scale(1, 1);
        transition: 1.2s all ease;

        &.zoom-in {
          transform: scale(1.4, 1.4);
        }
      }

      h1 {
        margin-bottom: 0px;
        margin-top: 0px;

        span {
          //font-family: @font-secondary;
          font-size: 100px;
          color: white;
          line-height: 120px;
          font-weight: 500;
          padding-right: 30px;
          position: relative;
          &.light {
            font-weight: 400;
            padding-right: 0;
          }
        }

        .tm {
          position: absolute;
          top: 30px;
          right: 0px;
          font-size: 30px;
          font-weight: 100;
          height: 30px;
          line-height: 30px;
        }
      }

      .apply-span {
        padding-top: 20px;
      }

      h2 {
        //font-family: @font-primary;
        font-size: 36px;
        color: #FFFFFF;
        line-height: 49px;
        font-weight: 400;
        margin-bottom: 0px;
      }

      .scroll-down-arrow {
        width: 60px;
        height: 60px;
        bottom: 15px;
        background: rgba(255, 255, 255, 0.40);
        color: @color-blue;
        text-align: center;
        line-height: 60px;
        font-size: 24px;
        border-radius: 30px;
        transition: @animate;
        cursor: pointer;

        &:hover {
          background: rgba(255, 255, 255, 0.6);
        }
      }

      .apply-now {
        position: absolute;
        right: 30px;
        top: -40px;

        &:not(.hermit) {
          top: 10px;
        }

        .btn {
          margin-left: 20px;
          color: white;

          &:hover {
            background-color: @color-light-red;
            color: white;
          }
        }
      }
    }

    .intro {
      position: relative;
      margin-top: 90px;

      .navbar {
        width: 100%;
        padding: 25px 50px;
        height: 90px;
        border-bottom: 1px solid @color-grey;
        background-color: white;
        position: absolute;
        top: -90px;
        z-index: 2;
        border-radius: 0px;

        .cover {
          padding: 25px 50px;
        }

        &.fixed {
          top: 0px;
          position: fixed;
        }

        .assets24 {
          position: relative;
          padding-right: 50px;
          margin-right: 50px;
          width: 170px;
          height: 40px;
          float: left;
          border-right: 1px solid @color-grey;
          span {
            color: @color-red;
            //font-family: @font-secondary;
            font-size: 25px;
            font-weight: 400;
            line-height: 36px;
            &.light {
              font-weight: 100;
            }
          }

          .tm {
            position: absolute;
            top: -5px;
            right: 58px;
            color: @color-blue;
            font-family: @font-secondary;
            font-size: 12px;
          }
        }

        ul {
          height: 40px;
          list-style: none;
          padding: 0;
          margin: 0;
          display: flex;
          justify-content: space-between;
          width: calc(~"100% - 230px");
          float: right;
          position: relative;
          z-index: 2;

          a {
            font-weight: 500;
            font-size: 13px;
            color: #2C2C2C;
            letter-spacing: 1px;
            line-height: 40px;
            transition: all 0.2s ease;
            cursor: pointer;

            &:hover {
              text-decoration: none;
              color: @color-orange;
            }
          }
        }
      }

      .users-number {
        font-family: @font-secondary;
        font-size: 80px;
      }

      .users-about {
        font-family: @font-secondary;
        font-size: 13px;
        color: @color-red;
        letter-spacing: 0.4px;
        line-height: 19px;
        margin: 0 auto;
        width: 100%;
        max-width: 580px;
      }

      .quote {
        /* 24Assets&reg; is a syste: */
        font-weight: 500;
        font-size: 16px;
        line-height: 28px;
      }

      .quote-author {
        font-family: 'ArialMT';
        font-size: 12px;
        letter-spacing: 0.46px;
        line-height: 21.87px;
      }
    }

    .what-is-it {
      background-color: @color-blue;
      color: white;

      p {
        color: white;
      }
    }

    .apply-now-banner {
      background-color: @color-red;
      color: white;

      .assets24 {
        position: relative;
        width: 170px;
        height: 40px;
        float: left;
        span {
          color: white;
          //font-family: @font-secondary;
          font-size: 25px;
          font-weight: 400;
          line-height: 36px;
          &.light {
            font-weight: 100;
          }
        }

        .tm {
          position: absolute;
          top: 0px;
          right: 53px;
          color: white;
          font-family: @font-secondary;
          font-size: 12px;
        }
      }

      .apply-now {
        float: right;
      }

      .btn {
        border: 0px;
        background-color: @color-blue;
        color: white;
        margin-left: 20px;
      }
    }
    .stat-box {
      padding: 30px 15px;
      border: 1px solid @color-red;
      width: 100%;
      max-width: 360px;
      margin-left: auto;
      margin-right: auto;
      .title {
        font-family: @font-secondary;
        font-size: 14px;
        letter-spacing: 0.4px;
        line-height: 16px;
        margin-bottom: 10px;
        &.alt {
          color: #fff;
        }
      }

      .text {
        font-family: @font-secondary;
        font-size: 18px;
        line-height: 24px;
        margin-top: 10px;
      }
    }
    .why-24assets {
      .tm {
        font-size: 12px;
        line-height: 32px;
        vertical-align: top;
      }


      .main-text {
        margin-top: 50px;
        margin-bottom: 50px;
      }
    }

    .app-image {
      overflow: hidden;
      position: relative;
      border-bottom: 1px solid #F8F8F8;
      .col-xs-12 {
        margin-bottom: -20px;
      }

      img {
        width: 100%;
      }
    }

    .are-you-ready {
      background-color: @color-blue;
      color: white;

      h4 {
        margin-bottom: 20px;
        margin-top: 50px;
      }

      i {
        font-size: 30px;
        padding: 0 10px;
        color: white;
        transition: @animate;

        &:hover {
          color: @color-red;
        }
      }
    }
  }

  @media (max-width: @laptop-max) {

    .Landing {

      .intro {

        .navbar {
          padding: 25px;

          .assets24 {
            padding-right: 20px;
            margin-right: 20px;
            width: 120px;

            span {
              font-size: 20px;
            }

            .tm {
              right: 15px;
            }
          }

          ul {
            width: calc(~"100% - 140px");
          }
        }
      }

      .apply-now-banner {
        background-color: @color-red;
        color: white;

        .assets24 {
          width: 120px;

          span {
            font-size: 20px;
          }

          .tm {
            right: 15px;
          }
        }
      }
    }
  }

  @media (max-width: @tablet-max) {

    .Landing {

      .logo {
        left: 20px;
      }

      .banner {

        h1 {

          span {
            font-size: 70px;
            line-height: 70px;
            padding-right: 30px;
            padding-left: 30px;
          }

          .tm {
            top: 10px;
          }
        }

        h2 {
          font-size: 24px;
          line-height: 36px;
        }

        .apply-now {
          right: 20px;
        }
      }

      .intro {
        margin-top: 0px;

        .navbar {
          position: fixed;
          top: -50px;
          left: 0px;
          height: 50px;
          z-index: 10;
          opacity: 0;
          transition: @animate;

          &.show-mobile {
            top: 0px;
            opacity: 1;
          }

          .cover {
            background-color: white;
            padding: 5px 15px;
            z-index: 2;
          }

          .assets24 {
            padding-right: 0px;
            margin-right: 0px;
            border: 0px;
          }

          ul {
            width: 100%;
            height: auto;
            padding: 20px 0;
            position: fixed;
            background-color: white;
            display: block;
            top: -200px;
            left: 0px;
            opacity: 0;
            z-index: -1;
            transition: @animate;
            border-bottom: 1px solid @color-grey;

            li {
              width: 100%;
              display: block;

              a {
                display: block;
                width: 100%;
                text-align: center;
              }
            }

            &.open {
              top: 52px;
              opacity: 1;
            }
          }

          .menu-toggle {
            width: 38px;
            height: 38px;
            border: 2px solid @color-orange;
            position: relative;
            border-radius: 3px;
            float: right;
            transition: @animate;
            cursor: pointer;

            .bar {
              position: absolute;
              left: 6px;
              background-color: @color-orange;
              width: 22px;
              height: 4px;
              transition: @animate;
              border-radius: 3px;
            }

            .bar1 {
              top: 8px;
            }

            .bar2 {
              top: 15px;
              transform-origin: 50% 50%;
            }

            .bar3 {
              top: 22px;
            }

            &:hover, &.open {
              background-color: @color-orange;

              .bar {
                background-color: white;
              }
            }

            &.open {

              .bar1 {
                transform: rotate(-405deg);
                top: 15px;
              }

              .bar2 {
                width: 4px;
                left: 15px;
              }

              .bar3 {
                transform: rotate(405deg);
                top: 15px;
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: @mobile-max) {

    .Landing {

      section {
        padding: 30px 0;
      }

      .banner {

        h1 {

          span {
            font-size: 35px;
            line-height: 35px;
            padding-right: 20px;
            padding-left: 20px;
          }

          .tm {
            top: 5px;
            font-size: 20px;
          }
        }

        h2 {
          font-size: 14px;
          line-height: 22px;
          margin-top: 0px;
        }

        .apply-span {
          padding-top: 0px;
        }
      }

      .intro {

        .navbar {
          padding: 25px;

          .assets24 {
            padding-right: 20px;
            margin-right: 20px;
            width: 120px;

            span {
              font-size: 20px;
            }

            .tm {
              right: 15px;
            }
          }
        }
      }

      .why-24assets {

        .flex {
          display: block;
        }

        .stat-box {
          margin-bottom: 10px;
        }

        .main-text {
          margin-top: 0px;
          margin-bottom: 20px;
        }
      }

      .apply-now-banner {

        .col-xs-12 {
          text-align: center;
        }

        .assets24 {
          width: 120px;
          margin: 0 auto;
          float: none;
          text-align: left;
          padding: 0 15px;

          span {
            text-align: center;
          }

          .tm {
            right: 0px;
          }
        }

        .btn {
          margin-left: 0px;
          margin-top: 10px;
        }
      }

      .are-you-ready {

        h4 {
          margin-bottom: 0px;
          margin-top: 10px;
        }
      }
    }
  }
}
//list with red squares
ul.squares {
  list-style-type: none;
  padding-left: 0;
  li {
    font-size: 14px;
    color: #fff;
    line-height: 16px;
    margin-bottom: 10px;
    font-weight: 500;
    padding-left: 25px;
    position: relative;
    &:before {
      content: ' ';
      background-color: @color-red;
      width: 14px;
      height: 14px;
      position: absolute;
      left: 0;
      top: 0px;
    }
  }
}
@media(max-width: 767px) {
  ul.squares {

    li {
      font-size: 13px;
      line-height: 22px;
      padding-left: 20px;
      &:before {
        width: 10px;
        height: 10px;
      }
    }
  }
}

//trademark styling
.quote-tm {
  font-size: 70%;
  vertical-align: top;
  line-height: 170%;
}
.sticky-bar-tm {
  font-size: 90%;
  vertical-align: top;
  line-height: 240%;
}
.apply-tm {
  font-size: 70%;
  display: inline;
  vertical-align: top;
  line-height: 160%;
}
span.blue {
  color: @color-blue !important;
  font-weight: 100 !important;
}
