

img {
  max-width: 100%;
}
.feat-title-wrap {
  max-width: 150px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 40px;
}
.feat-img {
  margin-bottom: 60px;
  .col-xs-6 {
    margin-bottom: 10px;
  }
}
.featured-in {
  h3 {
    font-size: 33px;
    line-height: 39px;
    color: #25306A;
    font-weight: normal;
  }
}
hr.secondary {
  border-bottom: 1px solid #D01328;
  margin: 10px 0;
  width: 100%;
  max-width: 160px;
  margin-left: auto;
  margin-right: auto;
}
.flex-helper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.border-left {
  border-left: 1px solid #ccc;
}
.border-bottom {
  border-bottom: 1px solid #ccc;
}
//updated css
.app-image {
  margin-bottom: 80px;
}
.why-important {
  h3 {
    margin-bottom: 0;
  }
  h4 {
    margin-top: 0;
  }
}
.why-24assets {
  p {
    max-width: 550px;
    margin-left: auto;
    margin-right: auto;

  }
  .stat-box {
    margin-top: 10px;
  }
}
.banner-list {
  max-width: 540px;
  margin: 30px auto 0;
  overflow: hidden;
  ul {
    width: auto;
    float: left;
    text-align: left;
    padding-right: 30px;
  }
}
.report {
  margin-bottom: 70px;
  img {
    max-width: 150px;
    margin-top: 10px;
  }
  .row {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 15px;
  }
}

@media(max-width: 991px) {
  .report {
    .border-left {
      border: none;
      margin-top: 20px;
    }
  }
}
.btm-slider, .slider {
  background-color: transparent !important;
}
.awards {
  padding: 15px 10px;
  margin-top: 60px;
  .col-xs-12 {
    margin-bottom: 40px;
  }
  h4 {
    display: block;
    padding: 0 0 10px;
  }
}
.award {
  margin-bottom: 10px;
}
.quote {
  margin-top: 70px;
}
.desktop-bg, .mob-bg {
  background-size: cover;
  background-repeat: no-repeat;
}
@media(max-width: 767px) {
  .report {
    margin-bottom: 40px;
  }
  .awards {
    margin-top: 0;
  }
  .quote {
    margin-top: 20px;
  }
}
@media(max-width: 767px) {
  .apply-now {
    width: 100%;
  }
  ul.squares li {
    line-height: 1;
    &:before {
      top: 2px;
    }
  }
  .stat-box {
    margin-bottom: 15px;
  }
  .banner-list {
    ul {
      padding-right: 0;
    }
  }
}


//the book section

.testimonial__img {
  max-width: 70px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.testimonial {
  position: relative;
  margin-top: 50px;
  p {
    padding-left: 105px;
  }
}
.testimonial__name {
  font-size: 12px;
  line-height: 21px;
  text-align: center;
}
@media(max-width: 767px) {
  .book__img {
    max-width: 200px;
    margin-bottom: 5px;
  }
  .testimonial__img {
    position: relative;
    transform: none;
    top: auto;
    display: block;
    margin: 0 auto 20px;
  }
  .testimonial {
    margin-top: 30px;
  }
  .testimonial p {
    padding-left: 0;
  }
  .testimonial__name {
    text-align: left;
  }
  .book__testimonial {
    hr.secondary {
      margin-left: 0;
    }
  }
}
