body.questions {

  background-image: url(/img/landing-banner.jpg);

  // Header
  .questions-header {
    position: absolute;
    height: 63px;
    width: 100%;

    .container, .row {
      height: 100%;
    }

    .logo {
      width: 190px;
      position: relative;
      z-index: 99;
      display: block;
      top: 50%;
      transform: translateY(-50%);

      img {
        width: 100%;
      }
    }
  }

  // Placeholder for absolute positioned header
  .header-placeholder {
    position: relative;
    width: 100%;
    height: 63px;
  }

  /*
      -- Main Questions Section
  */
  .section-questions {
    margin-top: 25px;
    margin-bottom: 75px;

    .container {
      background-color: #fff;
      padding: 65px 110px;

      .row {
      }
    }

    h1 {
      font-size: 20px;
      color: #D61F35;
      font-weight: 400;
      font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
      -webkit-font-smoothing: antialiased;
      text-align: center;
      margin-bottom: 30px;
    }

    .questions {
      .question {
        display: none;
        text-align: center;

        .question-number {
          margin-bottom: 30px;

          h2 {
            font-size: 32px;
            color: #273167;
            font-weight: 500;
            text-align: center;
            margin-top: 0px;
          }

          .col-md-12 {
            &:after {
              content: "";
              background-color: #EAE8E8;
              width: 100px;
              height: 2px;
              position: absolute;
              top: 95%;
              left: 0;
              right: 0;
              margin-left: auto;
              margin-right: auto;
            }
          }
        }

        .question-content {
          font-size: 32px;
          color: #273167;
          font-weight: 400;
          font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
          -webkit-font-smoothing: antialiased;
          text-align: center;
          margin-bottom: 30px;
          line-height: 38px;
          min-height: 125px;
        }

        .range-slider-wrapper {
          display: flex;
          align-items: center;

          span {
            color: #273167;
            font-weight: 600;
            font-size: 20px;
            flex-basis: 130px;
          }
          .range-slider {
            flex: 1;

            input[type=range] {
              -webkit-appearance: none;
              width: 100%;
              background: linear-gradient(to right, #CF273E 0%, #CF273E 33%, #FFCB00 50%, #38B54A 66%, #38B54A 100%);
              height: 15px;
            }

            input[type=range]::-webkit-slider-thumb {
              -webkit-appearance: none;
              width: 40px;
              height: 40px;
              border-radius: 50%;
              background-color: #D8D8D8;
            }

            input[type=range]:focus {
              outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
            }

            input[type=range]::-ms-track {
              width: 100%;
              cursor: pointer;

              /* Hides the slider so custom styles can be added */
              background: #EAE8E8;
              border-color: transparent;
              color: transparent;
            }
          }
        }

        &.active {
          display: block;
        }
      }

      .buttons {
        margin-top: 90px;
        text-align: center;

        .btn-primary {
          font-size: 20px;
          font-weight: 600;
          width: 150px;
          height: 50px;
          border-radius: 0px !important;
          text-decoration: none !important;
          background-color: #EB5C18;
          text-align: center;
          display: inline-block;
          margin: 0px 10px;
          padding: 0 20px;
          &.btn-prev {
            background-color: #EAE8E8;
            color: #626262;
          }
          &:active {
            background-color: darken(#EB5C18, 10%)
          }

          &:focus {
            color: #fff;
          }

          &.hidden {
            visibility: hidden;
          }
        }
      }
    }
  }

  .loader {
    display: none;
    max-width: 200px;
    margin: 0 auto;
    margin-top: 50px;

    img {
      width: 100%;
    }
  }

  footer {
    display: none !important;
  }

  @media screen and (max-width: 768px) {
    .section-questions {
      .container {
        padding: 50px 50px;
        margin: 0px 30px;
      }
    }
  }

  @media screen and (max-width: 640px) {
    .section-questions {
      .questions {
        .question {
          .question-number {
            margin-bottom: 5px;
          }
          .question-content {
            font-size: 27px;
            margin-bottom: 25px;
            line-height: 33px;
          }
          .range-slider-wrapper {

            span {
              flex-basis: 75px;
              font-size: 15px;
            }

            .range-slider {

            }
          }
        }

        .buttons {
          .btn-primary {
            font-size: 14px;
            width: 110px;
            height: 50px;
            margin: 0px 5px;
            padding: 0px !important;
            padding-top: 0px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 420px) {
    .section-questions {
      .container {
        padding: 30px 30px;
        margin: 0px 20px;
      }

      h1 {
        font-size: 18px;
        line-height: 23px;
        margin-bottom: 20px;
        margin-top: 0px;
      }

      .questions {
        .question {
          .question-number {
            h2 {
              font-size: 30px;
              margin-top: 0px;
            }

            &:after {
              top: 110%;
            }
          }
          .question-content {
            font-size: 22px;
            margin-bottom: 25px;
            line-height: 28px;
            margin-top: 0px;
          }
        }

      }
    }
  }

  @media screen and (max-width: 350px) {
    .section-questions {
      .container {
        padding: 30px 15px;
        margin: 0px 0px;
      }
    }
  }

}
@media(max-width: 767px) {
  body.questions .section-questions .questions .buttons {
    margin-top: 40px;
  }
  body.questions .section-questions .questions .question .question-content {
    min-height: auto;
  }
}
