.app-header{
  height:60px;
  opacity:1;
  position:absolute;
  overflow:hidden;
  top:0px;
  left:0px;
  width:100%;
  z-index:2;
  opacity:0;
  visibility:hidden;
  transition:@animate-fast;

  &.visible{
    opacity:1;
    visibility:visible;
  }

  .logo{
    position:absolute;
    top:0px;
    left:60px;

    img{
      width:100%;
      width:auto;
      height:60px;
    }
  }

  .btn{
    margin-top:10px;
    margin-right:10px;
  }
}
