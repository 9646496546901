//element styles overriding the transffered ones if needed as further down the cascade.
@primaryColor : #242E6C;
@secondaryColor: #D60016;
h1 {

}
h2 {
  font-size: 36px;
  line-height: 44px;
  font-weight: 300;
}
h3.bordered {
  text-transform: uppercase;
  color: #fff;
  display: inline-block;
  font-weight: 400;
  font-size: 22px;
  line-height: 28px;
  padding: 10px 30px;
  margin-bottom: 20px;
  border-top: 1px solid @color-red;
  border-bottom: 1px solid @color-red;
}
h3 {
  font-weight: 400;
  font-size: 33px;
  line-height: 39px;
  letter-spacing: -1px;
  color: @primaryColor;
  margin-top: 0;
  margin-bottom: 15px;
  &.alt {
    color: #fff;
  }
}
h4 {
  font-size: 22px;
  line-height: 28px;
  color: @secondaryColor;
  font-weight: 400;
  margin-bottom: 15px;
}
h5 {
  font-size: 15px;
  line-height: 18px;
  font-family: HelveticaNeueETW01-75Bd,arial,sans-serif;
  color: #263267;
  letter-spacing: -.5px;
  margin-bottom: 0;
  &.alt {
    color: #fff;
    border-bottom: 1px solid @secondaryColor;
    display: inline-block;
    padding-bottom: 5px;
  }
  &.secondary {
    font-size: 16px;
    line-height: 25px;
    color: #626262;
    font-weight: 600;
  }
}
h6 {
  color: @secondaryColor;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  margin-bottom: 10px;
  margin-top: 0;
  &.alt {
    color: #fff;
  }
  &.secondary {
    font-size: 12px;
    line-height: 21px;
    color: #626262;
  }
}
p {
  font-size: 16px;
  line-height: 28px;
  margin-bottom: 15px;
}