body.results {
  .btn {
    &:hover {
      text-decoration: none;
    }
  }
  //background-image: url('/img/landing-banner.jpg');
  // Header
  .questions-header {
    position: absolute;
    height: 63px;
    width: 100%;

    .container, .row {
      height: 100%;
    }

    .logo {
      max-width: 160px;
      position: relative;
      display: block;
      top: 50%;
      transform: translateY(-50%);
      img {
        width: 100%;
      }
    }
  }

  // Placeholder for absolute positioned header
  .header-placeholder {
    position: relative;
    width: 100%;
    height: 63px;
  }

  /*
      -- Main results Section
  */
  .section-results {
    margin-top: 25px;
    margin-bottom: 75px;
    padding-bottom: 0;
    .container {
      background-color: #fff;
      padding: 70px 200px;
      @media (min-width: 1200px){
        width: 1000px
      }
    }
    h1 {
      font-size: 32px;
      color: #273167;
      font-weight: 200;
      text-align: center;
      -webkit-font-smoothing: subpixel-antialiased;
    }
    .email {
      font-size: 20px;
      font-weight: 500;
      color: #273167;
      text-align: center;
      -webkit-font-smoothing: subpixel-antialiased;
      span {
        color: @secondaryColor;
      }
    }
    a {
      font-size: 16px;
      font-weight: 500;
      color: #273167;
      text-align: center;
      position: relative;
      display: block;
      -webkit-font-smoothing: subpixel-antialiased;

      &:after {
        content: "";
        background-color: #273167;
        height: 1px;
        position: absolute;
        width: 160px;
        top: 113%;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
      }
    }

    h2 {
      font-size: 32px;
      color: #273167;
      font-weight: 200;
      text-align: center;
      -webkit-font-smoothing: subpixel-antialiased;
      margin-top: 0;
      line-height: 32px;
      margin-bottom: 1
    }
    p.info {
      font-weight: 500;
      font-size: 20px;
      text-align: center;
      -webkit-font-smoothing: subpixel-antialiased;
      line-height: 24px;
      color: #273167;
    }

    .score-guide {
      display: flex;
      justify-content: space-between;
      margin-top: 30px;

      .score {
        flex-basis: 90px;
        position: relative;
        .color {
          width: 90px;
          height: 90px;
          background-color: #333;
          border-radius: 50%;
        }
        &.amber {
          span {
            position: absolute;
            left: -85%;
          }
        }
        span {
          font-size: 20px;
          font-weight: 500;
          text-align: center;
          margin-top: 12px;
          color: #273167;
          width: 100%;
          display: block;
        }

        &.red {
          .color {
            background-color: @secondaryColor;
          }
        }
        &.orange {
          .color {
            background-color: #F26528;
          }
        }
        &.amber {
          .color {
            background-color: #E5BF33;
          }
        }
        &.lgreen {
          .color {
            background-color: #74C270;
          }
        }
        &.green {
          .color {
            background-color: #38B54A;
          }
        }
      }
    }

    @media screen and (max-width: 1200px) {
      .container {
        padding: 70px 130px;
      }
    }

    @media screen and (max-width: 992px) {
      .container {
        padding: 50px 80px;
      }
    }

    @media screen and (max-width: 768px) {
      .container {
        margin: 0px 20px;
      }
    }

    @media screen and (max-width: 700px) {
      .score-guide {
        .score {
          flex-basis: 80px;

          .color {
            width: 80px;
            height: 80px;
          }
        }
      }
    }

    @media screen and (max-width: 640px) {

      .container {
        padding: 30px 40px;
      }

      h1 {
        font-size: 25px;
      }

      .email {
        font-size: 15px;

        span {
          color: @secondaryColor;
        }
      }

      a {
        font-size: 13px;

        &:after {
          width: 140px;
          top: 110%;
        }
      }

      h2 {
        font-size: 26px;
        margin-top: 40px;
      }

      p.info {
        font-size: 16px;
        line-height: 20px;
      }

      .score-guide {
        flex-wrap: wrap;

        .score {
          flex-basis: 60px;

          .color {
            width: 60px;
            height: 60px;
          }
          span {
            font-size: 15px;
            margin-top: 9px;
          }
        }
      }
    }

    @media screen and (max-width: 450px) {
      .container {
        padding: 30px 15px;
      }

      .score-guide {
        flex-wrap: wrap;
        flex-direction: column;
        text-align: center;
        justify-content: center;

        .score {
          margin-bottom: 30px;

          .color {
            width: 90px;
            height: 90px;
            display: block;
            margin: 0 auto;
          }
        }
      }
    }

  }
  .results-content {
    background-color: #FBFBFB;
    .container {
      background-color: #ffffff;
      @media (min-width: 1200px){
        width: 1000px
      }
    }
  }
  footer {
    display: none !important;
  }
  //additional css
  .Landing {
    .logo {
      z-index: 2;
    }
    .banner {
      height: auto;
      padding-bottom: 0;

    }
    section.section-results {
      background: transparent;
      z-index: 2;
      position: relative;
      width: 100%;
      margin-bottom: 0;
      padding-top: 0;
      h1 {
        span {
          font-size: 18px;
          color: #273167;
          line-height: 21px;
          font-weight: 500;
          padding-right: 0px;
          position: relative;
          vertical-align: top;
        }
      }
    }
    .background-cover {
      z-index: 1;
    }
    .guide-container {
      border: 1px solid @secondaryColor;
      padding: 45px;
      h2 {
        margin-bottom: 10px;
      }
    }
  }
}
.social-share {
  padding: 15px 0 35px;
  margin-bottom: 50px;
  .total-shares {
    display: inline-block;
    text-align: right;
    border-right: 3px solid @secondaryColor;
    padding-right: 25px;
    margin-right: 25px;
    margin-top: 20px;
    span {
      display: block;
      font-size: 24px;
      line-height: 28px;
      font-family: HelveticaNeueETW01-75Bd,arial,sans-serif;
      font-weight: 700;
      &.number {
        font-size: 64px;
        line-height: 75px;
        color: @secondaryColor;
      }
    }
  }
  .share-options {
    display: inline-block;
    margin-top: 20px;
    text-align: center;
    .link {
      width: 68px;
      height: 68px;
      display: block;
      margin: 15px;
      float: left;
      color: @secondaryColor;
      margin-top: 8px;
      &:hover, &:active, &:focus {
        text-decoration: none;
      }
      &.facebook {
        i {
          color: #465995;
        }

        .border {
          border-color: #465995;
        }
      }
      &.twitter {
        i {
          color: #5D9FEF;
        }

        .border {
          border-color: #5D9FEF;
        }
      }
      &.linkedin {
        i {
          color: #4376B2;
        }

        .border {
          border-color: #4376B2;
        }
      }
      &.pinterest {
        i {
          color: #A10A21;
        }

        .border {
          border-color: #A10A21;
        }
      }
    }
    .link .border {
      display: block;
      float: left;
      border: 1px solid @secondaryColor;
      border-radius: 50%;
      color: @secondaryColor;
      width: 100%;
      height: 100%;
      margin-bottom: 5px;

      i {
        font-size: 30px;
        line-height: 55px;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    .share-number {
      font-family: HelveticaNeueETW01-75Bd,arial,sans-serif;
      font-weight: 700;
    }
  }
}
@media(max-width: 600px) {
  .social-share {
    margin-bottom: 20px;
    .mob-align {
      display: flex;
      align-items: center;
    }
    .share-options {
      margin-top: 0;
      .link {
        width: 38px;
        height: 38px;
        margin: 5px;
        i {
          font-size: 20px !important;
        }
      }
    }

    .total-shares {
      padding-right: 15px;
      margin-right: 10px;
      margin-top: 20px;
      span {
        font-size: 14px;
        line-height: 18px;
        &.number {
          font-size: 34px;
          line-height: 35px;
        }
      }
    }
  }
}

//margins
body.results .section-results {
  h1 {
    margin-bottom: 15px;
  }
  p.email {
    margin-bottom: 15px;
  }
}
